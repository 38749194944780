<style lang="scss" scoped>
    @use '~ihunter-pathfinder/styles/legacy/common.scss' as Legacy;

    .results {
        padding-top: Legacy.$xsmall-size;
        display: flex;
        flex-direction: column;
        width: 100%;
        
        header {
            @include Legacy.icon-section;
        
            .content {

                .section {
                    position: relative;
                    display: flex;
                    flex-direction: column;

                    width: 100%;
                }
            }
        }
    }

</style>

<template>

    <div class="results">
        <header>
            <img class="profile icon" :src="photo" referrerpolicy="no-referrer" v-if="authenticated">
            <div class="content">
                <div class="secondary-title">{{!count?"No":count}} product{{count!==1?"s":""}} found</div>
                <div class="title">Search results</div>
                <chip/>
                <div v-if="count" class="section">
                    <!-- <button @click="addAll">Add {{count===1?"product":"all products"}} to cart</button> -->
                    <a href="javascript:void(0)" v-if="count < 2" @click="addAll" class="arrow"><cart-icon></cart-icon> Add product to cart</a>
                    <a href="javascript:void(0)" v-else-if="count > 1" @click="addAll" class="arrow"><cart-icon></cart-icon> Add all products to cart</a>


                    <div class="card-grid">
                        <product-list :products="results"></product-list>
                        <!-- <product-card v-for="(product, sku) in results" :key="sku" :sku="sku" :product-info="product"></product-card> -->
                    </div>
                </div>
                <p v-else class="details">No product names found using the search term '{{args}}'.</p>
            </div>
        </header>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import Page from './Page.vue';

    @Component
    export default class SearchResultsPage extends Page {

        get count() {
            return Object.keys(this.results).length;
        }

        mounted() {
            if(this.args && (!this.state.search || this.state.search === '')) {
                this.state.search = this.args;
            }
        }

        addAll() {
            for(let sku in this.results) {
                this.cart.buy(sku);
            }
        }

        get results() {
            console.log("Searching: " + this.args);

            let results = {};

            const terms = this.args.toLowerCase().split(' ');
            const products = this.state.searchableProducts;
            for(let sku in products) {
                const product = products[sku];
                if(!product?.name) {
                    console.warn(`Missing product name for '${sku}'`);
                    continue;
                }
                const name = product.name.toLowerCase();
                let match = false;

                for(let term of terms) {
                    if(name.indexOf(term) !== -1) {
                        match = true;
                        break;
                    }
                }
                if(match) {
                    results[sku] = product;
                }
            }
            return results;
        }
    }

    Vue.component('search-results-page', SearchResultsPage);

</script>

