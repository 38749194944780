<style lang="scss" scoped>
    @use '~ihunter-pathfinder/styles/legacy/common.scss' as Legacy;

    .map-product {
        padding-top: Legacy.$xsmall-size;
        
        header {
            @include Legacy.icon-section;
        }

        footer {
            margin: Legacy.$gap;
        }

        .revision {
            margin-right: Legacy.$space;
            display: inline-flex;
        }
    }

</style>

<template>

    <div class="map-product">
        <header v-if="validSku">

            <div class="top">
                <div class="info show-smaller">
                    <div class="alternate" v-if="alternate">${{alternate}}</div>
                    <div class="price">${{price}}</div>
                </div>
                <product-icon class="icon"></product-icon>
            </div>

            <div class="content">
                <div class="secondary-title">{{info?.version}} Landowner Map</div>
                <div class="info show-bigger">
                    <div class="alternate" v-if="alternate">${{alternate}}</div>
                    <div class="price">${{price}}</div>
                </div>

                <div class="title">{{name}}</div>
                <chip/>
                <p>Additional boundary and landowner information for <b>{{name}}</b>.</p>
                <p>{{metadata?.description}}</p>

                <div class="details">Like all other iHunter map layers you can toggle display on/off and control the opacity to customize your experience. Landowner maps are also cacheable so you can download them to your device and be ready to go offline.</div>

                <product-notice :sku="sku" :product-info="info"></product-notice>
                <!-- <div class="product-notice update" v-if="alternate">
                    <img class="profile" :src="photo" referrerpolicy="no-referrer">
                    <div>
                        <div>You own a previous version of this map.</div>
                        <div><b>Save {{upgradeDiscount}}%</b> when you upgrade to the newest year.</div>
                    </div>
                    <sash :small="true"></sash>
                </div>

                <div class="product-notice" v-else-if="purchase">
                    <img class="profile" :src="photo" referrerpolicy="no-referrer">
                    <div>
                        <div>This map is already on your account.</div>
                    </div>
                </div> -->

                <product-cart-button :sku="sku" :product="info" :full="true"></product-cart-button>

                <div class="secondary-title">works with</div>
                <div class="product-list">
                    <div class="product-logo">
                        <app-icon :region="region"></app-icon><word-logo :dark="true">{{short}}</word-logo>
                    </div>
                    <div class="product-logo">
                        <web-app-icon></web-app-icon><word-logo :dark="true">Web</word-logo>
                    </div>
                </div>

                <!-- <br/> -->
                <div class="secondary-title" v-if="history.length > 0">Release history</div>
                <div class="product-list">
                    <div class="revision" v-for="(version, index) of history" :key="index">
                        <span>{{ version }}</span>
                        <span v-if="index !== history.length-1">,</span>
                    </div>
                </div>

                <divider/>

                <div class="details">{{layer?.what_is_description}}</div>
                <div class="fine-print">
                    <p>All landowner maps are approximate and should not be used as precise GPS property boundaries, but general guidelines. Landowner names were accurate as of the year listed but may have changed since that date. Neither iHunter nor the copyright holder that created the map accept any responsibility for inaccuracies or incorrect information. Use and rely on the map at your own risk.</p>
                    <p>If you find inaccuracies or otherwise have issues with your map purchase please <a href="#/support/">contact support</a> so we can help.</p>
                </div>
            </div>
        </header>

        <header v-else>        
            <app-icon class="icon"></app-icon>
            <div class="content">
                <div class="secondary-title">Product details</div>
                <div class="title">Map not found</div>
                <chip/>
                <p>Sorry, we can't find details for the product '<b>{{sku}}</b>' in our catalog.</p>
                <p>Go <a href="javascript:history.back()">back</a> or navigate <a href="/">home</a>.</p>
            </div>
        </header>


<!-- 
        <footer v-if="validSku">
            <h3>Product information</h3>
            <json-viewer v-if="info" :value="info"></json-viewer>
            <h4>Product Metadata</h4>
            <json-viewer v-if="metadata" :value="metadata" :expand-depth="0"></json-viewer>
            <h4>Layer Metadata</h4>
            <json-viewer v-if="layer" :value="layer" :expand-depth="0"></json-viewer>
        </footer> -->
    </div>
</template>

<script>
    import { Component, Watch, Vue } from 'vue-property-decorator';

    import ProductPage from './ProductPage.vue';

    @Component
    export default class MapProductPage extends ProductPage {

        metadata = null;
        layer = null;

        mounted() {
            this.loadMetadata().then(metadata => {
                this.metadata = metadata;
            }).catch(error => {
                console.error(error);
            });

            this.loadLayerMetadata().then(metadata => {
                this.layer = metadata;
            }).catch(error => {
                console.error(error);
            });
        }
        
        async loadMetadata() {
            if(this.product.region && this.product.resource && this.product.layer && this.product.map && this.sku) {
                return await this.state.catalogProvider.getResourceLayerProductMetadata(this.product.region, this.product.resource, this.product.layer, this.product.map, this.sku);
            }
            return null;
        }

        async loadLayerMetadata() {
            if(this.product.region && this.product.resource && this.product.layer) {
                return await this.state.catalogProvider.getResourceLayerMetadata(this.product.region, this.product.resource, this.product.layer);
            }
            return null;
        }

        get summary() {
            return this.catalog[this.sku];
        }

        get product() {
            return this.products[this.sku];
        }

        get info() {
            if(!this.validSku) {
                return null;
            }
            return this.state.productInfo(this.sku);
        }

        get purchase() {
            return this.info?.purchase;
        }

        get alternate() {
            return this.info?.alternate;
        }

        get version() {
            return this.info?.version;
        }

        get name() {
            return this.info?.name;
        }

        get history() {
            let history = [];

            const product = this.product;
            if(!product?.region || !product?.resource || !product?.layer || !product?.map) 
            {
                return history;
            }

            let map = this.catalog[product?.region]?.resources[product?.resource]?.layers[product?.layer]?.maps[product?.map];
            if(!map) {
                return history;
            }

            let revisions = map?.products;
            if(revisions && Object.keys(revisions).length > 1) {
                for(let revision in revisions) {
                    let version = revisions[revision].version
                    //if(version !== this.version) {
                        history.push(version);
                    //}
                }
            }

            return history;
        }

    }

    Vue.component('map-product-page', MapProductPage);

</script>

