<style lang="scss" src="./HeaderTab.vue" scoped></style>

<style lang="scss" scoped>
    @use '~ihunter-pathfinder/styles/legacy/common.scss' as Legacy;

    .header-tab {
        flex-direction: row;
        justify-content: center;

        //@include dynamic-padding-left;
        padding-left: calc(0.5*(100vw - Legacy.$max-6up));

        &.active {
            .tab-item {
                background: Legacy.$mono-black;
            }
        }

        .tab-item {
            flex-direction: row;

            .section {
                margin: auto Legacy.$gap 0;
            }
        }

        .wordmark {
            margin: 0 Legacy.$space;
            pointer-events: all;
            cursor: pointer;

            @include Legacy.below(large)  {
                display: none;
            }
        }

        .label {
            margin: Legacy.$gap Legacy.$gap Legacy.$space 0;
            display: none;

            @include Legacy.above(large)  {
                display: flex;
            }
        }

        .word-logo {
            display: none;
            @include Legacy.above(min)  {
                display: flex;
            }

            .logo-label {
                display: none;

                @include Legacy.above(small)  {
                    display: initial;
                }
            }
        }
    }

</style> 

<template>
    <div class="header-tab" @click="click" @mouseover="hover = true" @mouseleave="hover = false">
        <div class="tab-item">
            <div class="section">
                <bag-pinkle type="header"></bag-pinkle>
                <div class="underline"></div>
            </div>
            <span class="label" v-if="label">{{label}}</span>
        </div>

        <word-logo>
            <span class="logo-label">Store</span>
        </word-logo>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import HeaderTab from './HeaderTab.vue';


    @Component
    export default class HeaderHomeTab extends HeaderTab {
        hover = false;
    }

    Vue.component('header-home-tab', HeaderHomeTab);

</script>

