<style lang="scss" scoped>
    @use '~ihunter-pathfinder/styles/legacy/common.scss' as Legacy;

    .section {
        position: relative;
        display: flex;
        flex-direction: column;

        width: 100%;

        .info {
            margin: Legacy.$space 0;
        }

        .details {
            margin: Legacy.$space 0;
        }
    }

</style>

<template>

    <div class="section">
        <div v-if="!count" class="info">
            It looks like all of your map layers are up to date. Thanks for being such an awesome iHunter user. Dynamic best wishes about a relevant holiday or season!
        </div>
        <div v-else class="info">
            <span v-if="count <= 6">{{wordForm(count)}}</span>
            <span v-else>{{count}}</span> of your maps have newer years available. All landowner maps purchased receive a {{upgradeDiscount}}% discount on all future updates.
        </div>

        <a href="javascript:void(0)" v-if="count < 2" @click="addAll" class="arrow"><cart-icon></cart-icon> Add map to cart</a>
        <a href="javascript:void(0)" v-else-if="count > 1" @click="addAll" class="arrow"><cart-icon></cart-icon> Add all {{count}} to cart</a>

        <div class="details">
            Remember, everything purchased here is linked to your iHunter account and will work everywhere you use iHunter. Make sure all devices are logged in with the same account.
        </div>

        <div class="card-grid">
            <product-card v-for="(product, sku) in updates" :key="sku" :sku="sku" :product-info="product"></product-card>
        </div>

    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import AppState from './AppState.vue';

    @Component
    export default class PurchaseUpdateSection extends Vue {

        addAll() {
            for(let sku in this.updates) {
                this.cart.buy(sku);
            }
        }

        wordForm(number) {
            const words = ['One', 'Two', 'Three', 'Four', 'Five', 'Six']; // TODO: Doesn't translate
            return words[Math.min(number-1, words.length-1)];
        }


        get count() {
            return this.updates ? Object.keys(this.updates).length : 0;
        }


        get state() {
            return AppState;
        }

        get cart() {
            return this.state.cart;
        }

        get updates() {
            return this.state.updates;
        }

        get upgradeDiscount() {
            return this.state.upgradeDiscount;
        }

        // get updates() {
        //     let updates = {};

        //     for(let product in this.state?.purchases){
        //         let info = this.state.productInfo(product);
        //         if(info?.update) {
        //             updates[info.update] = this.state.productInfo(info.update);
        //         }
        //     }

        //     return updates;
        // }

        productVersion(sku) {
            return this.state?.products[sku]?.version;
        }
    }

    Vue.component('purchase-update-section', PurchaseUpdateSection);

</script>

