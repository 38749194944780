<style lang="scss" scoped>
 @use '~ihunter-pathfinder/styles/legacy/common.scss' as Legacy;

    .notification {
        padding: Legacy.$space;
        background-color: Legacy.$validation-bad-dark;
        width: 100%;

        @include Legacy.Notification;

        .error-block {
            display: flex;
            justify-content: space-between;
        }
    }

    .close {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: Legacy.$gap;
        height: Legacy.$gap;
        font-size: 16px;

        &:before {
            content: 'x';
            color: Legacy.$mono-glow;
        }

        &:hover {
            &:before {
                color: Legacy.$mono-white;
            }
        }
    }

    .notice {
        color: Legacy.$mono-glow;
    }

    


</style>

<template>
    <div class="notification" v-if="error.found">
        <div class="error-block" v-for="(message, i) in error.notice" :key="i">
            <div><b>{{message}}</b></div>
            <div class="close" @click="dismiss(i)"></div>
        </div>
        <div class="notice">If the problem persists, please <a href="#/support/">contact support</a> for further assistance.</div>
    </div>
</template>

<script>

    import { Component, Vue } from 'vue-property-decorator';
    import AppState from './AppState.vue';

    @Component
    export default class ErrorBar extends Vue {

        dismiss(key) {
            this.error.dismiss(key);
        }

        get error() {
            return this.state.error;
        }

        get state() {
            return AppState;
        }
    }

    Vue.component('error-bar', ErrorBar);

</script>
