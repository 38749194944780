<style lang="scss" src="./HeaderTab.vue" scoped></style>

<style lang="scss" scoped>
    @use '~ihunter-pathfinder/styles/legacy/common.scss' as Legacy;

    .header-tab {
        @include Legacy.above(large)  {
            .tab-item {
                width: 0.5 * Legacy.$aside-width;
            }

            .close {
                display: none;
            }
        }


        .label {
            @include Legacy.below(large)  {
                display: none;
            }
        }
    }
</style> 

<template>
    <div class="header-tab" :class="{active}" @click="click" @mouseover="hover = true" @mouseleave="hover = false">
        <div class="tab-item">
            <div class="close"></div>
            <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path v-if="!hover && !active" d="m148.909 926.815 1.9 9.044h11.079a2.289 2.289 0 0 0 2.19-1.655l1.9-6.866a.411.411 0 0 0-.07-.356.441.441 0 0 0-.342-.167h-16.657m-3.409-5.565h.352a2.337 2.337 0 0 1 2.275 1.847l.572 2.718h16.876a1.441 1.441 0 0 1 1.13.553 1.409 1.409 0 0 1 .245 1.235l-1.9 6.871a3.29 3.29 0 0 1-3.153 2.385h-10.873l.144.686a1.333 1.333 0 0 0 1.3 1.054h10.382a.5.5 0 1 1 0 1h-10.384a2.336 2.336 0 0 1-2.276-1.848l-3.042-14.451a1.332 1.332 0 0 0-1.3-1.053h-.348a.5.5 0 1 1 0-1zm7.25 19a1.25 1.25 0 1 1-1.25 1.25 1.25 1.25 0 0 1 1.25-1.25zm8.5 0a1.25 1.25 0 1 1-1.25 1.25 1.25 1.25 0 0 1 1.25-1.25z" transform="translate(-144 -920)" style="fill:#fff"/>
                <path v-else d="M33.5 1.25h.352a2.337 2.337 0 0 1 2.275 1.85l.573 2.715h16.875a1.438 1.438 0 0 1 1.13.553A1.41 1.41 0 0 1 54.95 7.6l-1.9 6.871a3.291 3.291 0 0 1-3.15 2.388H39.024l.144.686a1.334 1.334 0 0 0 1.3 1.054H50.85a.5.5 0 0 1 0 1H40.466a2.336 2.336 0 0 1-2.276-1.848L35.148 3.3a1.332 1.332 0 0 0-1.3-1.053H33.5a.5.5 0 0 1 0-1zm7.25 19a1.25 1.25 0 1 1-1.25 1.25 1.25 1.25 0 0 1 1.25-1.25m8.5 0A1.25 1.25 0 1 1 48 21.5a1.25 1.25 0 0 1 1.25-1.25" transform="translate(-32)" style="fill:#fff"/>
            </svg>
            <span class="label">{{label}}</span>
            <div class="underline"></div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import HeaderTab from './HeaderTab.vue';


    @Component
    export default class HeaderCartTab extends HeaderTab {
        hover = false;
    }

    Vue.component('header-cart-tab', HeaderCartTab);

</script>

