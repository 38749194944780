<style lang="scss" scoped>
@use '~ihunter-pathfinder/styles/legacy/common.scss' as Legacy;

.purchases {
    padding-top: Legacy.$xsmall-size;

    @include Legacy.icon-section;
}
</style>

<template>
    <div class="purchases">
        <profile-icon class="profile icon" :icon="photo"></profile-icon>

        <div class="content">
            <div class="secondary-title">My account</div>
            <div class="title">My purchases</div>
            <chip />

            <!-- <div v-if="complete"> -->
                <div class="product-notice update" v-if="purchaseComplete">
                    <img class="profile" :src="photo" referrerpolicy="no-referrer">
                    <div>
                        <div>Checkout success</div>
                        <div>New products have been added to your account!</div>
                    </div>
                    <sash :small="true"></sash>
                </div>
                <!-- <h3>Checkout complete</h3>
                <p>New products have been successfully added to your account!</p> -->
            <!-- </div> -->


            <p>Here is every purchase associated with your iHunter Account. On the right you can see where the purchase was
                made to help you keep track of things that were purchased directly through the mobile apps.
                Use the <a href="/#/billing">Manage Billing</a> page to make any changes to your billing info or manage subscriptions.</p>
            <p class="details">Remember, everything purchased here is linked to your iHunter account and will work
                everywhere you use iHunter. Make sure all devices are logged in with {{user.email}}.</p>
            <purchase-summary></purchase-summary>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import Page from './Page.vue';

@Component
export default class PurchasesPage extends Page {
    mounted() {
        if (!this.authenticated) {
            this.navigate('login', 'purchases'); // Navigate back to home
            return;
        }

        // if(this.purchaseComplete) {
        //     this.checkoutComplete();
        // }
    }

    get purchaseComplete() {
        return this.args == 'complete';
    }
}

Vue.component('purchases-page', PurchasesPage);

</script>

