<style lang="scss" scoped>
    @use '~ihunter-pathfinder/styles/legacy/common.scss' as Legacy;

    .chip {
        margin-bottom: Legacy.$space;
    }

</style>

<template>
    <div class="chip">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="4" viewBox="0 0 32 4">
            <path data-name="Chip" d="M.444 0h31.112A.444.444 0 0 1 32 .444 3.556 3.556 0 0 1 28.444 4h-28A.444.444 0 0 1 0 3.556V.444A.444.444 0 0 1 .444 0z" style="fill:#a61919"/>
            <path data-name="Chip" d="M.5 0H24a4 4 0 0 1-4 4H.5a.5.5 0 0 1-.5-.5v-3A.5.5 0 0 1 .5 0z" style="fill:#d92121"/>
        </svg>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    @Component
    export default class Chip extends Vue {

    }

    Vue.component('chip', Chip);

</script>

