<style lang="scss" scoped>
@use '~ihunter-pathfinder/styles/legacy/common.scss' as Legacy;

.support {
    padding-top: Legacy.$xsmall-size;

    @include Legacy.icon-section;

    .form-row {
        display: flex;
        flex-direction: row;

        span {
            margin: 0 4px;
        }

        input[type=text],
        textarea {
            flex-grow: 1;
            max-width: 256px;
        }
    }

    .form-group {
        display: flex;
        flex-direction: column;
    }

    button {
        width: Legacy.$unit;
    }

    .video-player {
        width: 100%;
        margin-bottom: Legacy.$icon-size;
    }
}
</style>

<template>
    <div class="support">
        <profile-icon v-if="authenticated && photo" class="profile icon" :icon="photo"></profile-icon>
        <img v-else class="bullpinkle icon"/>

        <div class="content">
            <div class="secondary-title">My account</div>
            <div class="title">Help and support</div>
            <chip />
            <p>Check out the resources below to help get the most out of iHunter. If you don’t see the answer you’re looking
                for please get in touch using the contact form at the bottom of this page.</p>
            <ul>
                <li><a href="javascript:void(0)" @click="navigate('support', 'purchases')">Purchases from other stores</a>
                </li>
                <li><a href="javascript:void(0)" @click="navigate('support', 'videos')">Walkthrough videos</a></li>
                <li><a href="javascript:void(0)" @click="navigate('support', 'contact')">Contact us</a></li>
            </ul>

            <divider />

            <h2 id="purchases">Purchases from other stores</h2>
            <chip />
            <p>iHunter is available in your desktop web browser and on your Android or iOS mobile device. If you’ve been
                with us for a while you might already have maps that you purchased there. Don’t worry. An iHunter purchase
                is an iHunter purchase. But you may need some more information on how they all work together, what it means
                to transfer them between platforms, and how purchasing from the iHunter Store can make things easier for you
                going forward.</p>
            <h3>iHunter Store purchases</h3>
            <p>Purchases made on <b>store.ihunterapp.com</b> are tied directly to your iHunter Account. That means they go
                with you wherever you use iHunter and sign in with the same details. Web browser, Android, iOS, your
                purchases will be there and work how you expect.</p>
            <p><platform-label :purchase="{ 'web': { 'mock': 'purchase' } }"></platform-label><span class="details">Look for this
                    icon on your purchases page to help identify them.</span></p>

            <h3>iOS purchases</h3>
            <p>Purchases made on iOS using the App Store are tied to the Apple ID on your device. This is possible even if
                you haven’t signed in to an iHunter Account. If you notice missing purchases on an iOS device, navigate to
                the <b>My Purchases</b> screen from the settings menu within iHunter and tap the <b>Restore Purchases</b>
                button.</p>
            <p><platform-label :purchase="{ 'apple': { 'mock': 'purchase' } }"></platform-label><span class="details">Look for
                    this icon on your purchases page to help identify them.</span></p>

            <h3>Android purchases</h3>
            <p>Purchases made on Android will be tied to your Google Play Account on your device. As long as you’re using
                the same Google Account on both devices you should have access to your purchases.</p>
            <p><platform-label :purchase="{ 'android': { 'mock': 'purchase' } }"></platform-label><span class="details">Look for
                    this icon on your purchases page to help identify them.</span></p>

            <divider />

            <h2 id="videos">Walkthrough videos</h2>
            <chip />
            <p>Check out our YouTube playlist for for walkthroughs on some of the powerful features available in the iHunter
                mobile app.</p>
            <iframe class="video-player" width="560" height="315"
                src="https://www.youtube.com/embed/videoseries?list=PLqBh7ZG8WbCx-IIkjuiAd70J5P1B5JJFR"
                title="iHunter Walkthrough videos" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                allowfullscreen></iframe>

            <divider />

            <h2 id="contact">Contact us</h2>
            <chip />
            <form @submit.prevent="submit" v-if="!sent">
                <div class="form-group">
                    <label for="product">Name</label>
                    <div class="form-row">
                        <input type="text" id="name" name="name" placeholder="Your name" v-model="name" required />
                    </div>
                </div>
                <div class="form-group">
                    <label for="email">Email</label>
                    <div class="form-row">
                        <input type="email" id="email" name="email" placeholder="Your email" v-model="email" required />
                    </div>
                </div>
                <div class="form-group">
                    <label for="price">Message</label>
                    <div class="form-row">
                        <textarea id="message" name="message" placeholder="Enter message here" rows="5" v-model="message"
                            required />
                    </div>
                </div>

                <br />
                <button type="submit" :disabled="processing"><span>Send</span></button>
            </form>
            <div v-else>
                <h3>Message sent</h3>
                <p>Thanks for your inquiry. We’ll be in touch as soon as possible.</p>
                <div class="detail">Click <a href="javascript:void(0)" @click="sent = false">here</a> to send another
                    message.</div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import Page from './Page.vue';

@Component
export default class SupportPage extends Page {
    email = "";
    name = "";
    message = "";
    processing = false;
    sent = false;

    async submit() {
        try {
            this.processing = true;
            await this.mail(this.name, this.email, this.message);
            this.sent = true;
            this.message = '';

        } catch (error) {
            console.error(error.message);

        } finally {
            this.processing = false;
        }
    }

}

Vue.component('support-page', SupportPage);

</script>

