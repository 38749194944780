<style lang="scss" scoped>
    @use '~ihunter-pathfinder/styles/legacy/common.scss' as Legacy;

    .item {
        position: relative;
        display: flex;
        flex-direction: row;
        // flex-grow: 1;
        justify-content: space-between;

        width: 100%;
        background-color: Legacy.$general-orange-light;

        border-radius: 4px;

        .info-section {
            margin: Legacy.$space Legacy.$space 0 0;
            flex-grow: 1;

            display: flex;
            flex-direction: column;
            
            .title {
                padding: 0 Legacy.$space;

                font-size: 13px;
                font-weight: normal;
                color: Legacy.$mono-black;
            }

            .secondary {
                padding: Legacy.$notch Legacy.$space; 

                text-align: left;
                @include Legacy.Text-11;

                color: Legacy.$mono-black;
            }
        }

        .badge {
            width: 30px;
            height: 30px;
            border-radius: 30px;
            background-color: Legacy.$mono-black;
            text-align: center;
            margin: Legacy.$gap 0 Legacy.$gap Legacy.$gap;
            padding-top: 2px;
        }

        .arrow {
            display: flex;
            margin: Legacy.$gap;
            fill: Legacy.$mono-white;

            svg { // Why is this hack margin required for BadgeMenuItems?!?
                margin-left: Legacy.$notch;
            }
        }

        &:hover {
            background-color: Legacy.$general-orange-glow;

            .arrow {
                fill: Legacy.$general-orange;
            }
        }

        &:focus, &:active {
            //background-color: Legacy.$mono-black;
            border: solid 2px Legacy.$general-orange;
        }
    }
    
</style>

<template>

    <div class="item">
        <div class="badge">
            {{count}}
        </div>

        <div class="info-section">
            <div class="title">{{title}}</div>
            <div class="secondary"><slot></slot></div>
        </div>

        <div class="arrow">
            <svg data-name="Icon 24 • Chevron-next" xmlns="http://www.w3.org/2000/svg" width="8" height="24" viewBox="0 0 8 24">
                <path d="m9.03 11.47-4-4a.75.75 0 0 0-1.061 1.061L7.439 12l-3.47 3.47a.75.75 0 0 0 1.061 1.061l4-4a.75.75 0 0 0 0-1.061" transform="translate(-2)"/>
            </svg>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    @Component
    export default class BadgeMenuItem extends Vue.extend({
        props: {
            title: {
                type: String,
                required: true
            },
            count: {
                type: Number,
                required: true
            }
        }
    }) {

    }

    Vue.component('badge-menu-item', BadgeMenuItem);

</script>

