<style lang="scss" scoped>
@use '~ihunter-pathfinder/styles/legacy/common.scss' as Legacy;

.billing {
    padding-top: Legacy.$xsmall-size;

    @include Legacy.icon-section;

    .platform-icon {
        width: Legacy.$medium-size;
        height: Legacy.$medium-size;

        &.apple {
            content: url("../assets/platform-icon-apple.svg");
        }

        &.android {
            margin-left: Legacy.$gap;
            content: url("../assets/platform-icon-android.svg");
        }
    }

    .subscription-title {
        margin-bottom: Legacy.$gap;
    }
}
</style>

<template>
    <div class="billing">
        <profile-icon class="profile icon" :icon="photo"></profile-icon>

        <div class="content">
            <div class="secondary-title">My account</div>
            <div class="title">Manage billing</div>
            <chip />
            <p>
                <span>All purchases made through the iHunter Store use Stripe to process payments to Inside Outside Studios
                    Inc.</span>
                <span v-if="portalAvailable">Using the link below will take you to a Stripe billing page where you can edit
                    your payment details.</span>
                <span v-else>Once you have made your first purchase you will be able to manage the billing details here.</span>
            </p>
            <p v-if="portalAvailable"><a href="javascript:void(0)" @click="portal" class="arrow">Edit payment details ▸</a></p>

            <divider />

            <h3 class="subscription-title">Active subscriptions
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" @click="refreshSubscriptions">
                    <path d="M14.98 3.759a.667.667 0 0 0-.886.322l-.84 1.8a5.662 5.662 0 1 0-.867 5.711.667.667 0 0 0-1.03-.847 4.337 4.337 0 1 1 .83-3.866L9.649 5.696a.667.667 0 1 0-.563 1.208l4.241 1.978 1.978-4.241a.667.667 0 0 0-.326-.882z" fill="#2875B4"/>
                </svg>
            </h3>

            <dialog-box v-if="processing" title="Refreshing subscriptions" :cancel="null" :action="null">
                <p>Your subscription details will refresh in just a moment...</p>
            </dialog-box>

            <p v-if="!subscriptions.length">No subscriptions found.</p>
            <billing-subscription-item v-else v-for="(info, index) of subscriptions" :key="index" :info="info" class="billing-item">
                <hr v-if="index !== subscriptions.length-1"/>
            </billing-subscription-item>
            
            <divider />

            <h3>App Store billing</h3>
            <p>If you have active subscriptions that you purchased from within the iHunter mobile app then the billing is
                handled by the appropriate App Store. Use the links below to be taken to the appropriate billing portal:</p>
            <div>
                <a href="https://support.apple.com/en-ca/HT204088" target="blank">
                    <img class="platform-icon apple" /></a>
                <a href="https://support.google.com/googleplay/answer/2850369?hl=en" target="blank">
                    <img class="platform-icon android" /></a>
            </div>
            <p>Should you wish to move your subscription payments to the iHunter Store instead, it's as simple as waiting
                for your annual subscription date to come up and then purchase one here instead of letting it renew from the
                App Store.</p>
            <p><b>Note:</b> You will have paid for a full year when you subscribed so be sure to make note of your renewal
                date.</p>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

// const { isEmpty } = require('ihunter-common');

import Page from './Page.vue';

@Component
export default class BillingPage extends Page {

    processing = null;

    mounted() {
        if (!this.authenticated) {
            this.navigate('login', 'billing');
            return;
        }

        this.state.refreshSubscriptions(); // Request silent refresh when we first view billing page
    }

    // getSubscription(sku) {
    //     return this.subscriptions.find((subscription) => subscription.sku === sku);
    // };

    get subscriptions() {

        let subscriptions = this.state.subscriptions;

        const getSubscription = (sku) => {
            return subscriptions.find((subscription) => subscription.sku === sku);
        };

        // Alright, we don't want to confuse the user by showing them multiple states for a single subscription group
        for (const subscription of subscriptions) {
            if (subscription.upgrade) {
                // Find if the upgrade subscription exists and is scheduled for phase out
                let upgrade = getSubscription(subscription.upgrade);
                if (upgrade?.status === 'phase-out') {
                    // Remove subscription and store it as a phasing subscription
                    subscription.phasing = upgrade;
                    subscriptions.splice(subscriptions.findIndex(phased => phased.sku === upgrade.sku), 1);
                }
            }
        }

        return subscriptions;
    }

    async refreshSubscriptions() {
        try {
            this.processing = true;
            await this.state.refreshSubscriptions();

        } catch (error) {
            this.state.error.notify(new Error(`Subscription refresh error (${error.message})`, error));

        } finally {
            this.processing = null;
        }
    }

    // async refreshData(delay) {
    //     setTimeout(() => {
    //         this.loadData();
    //     }, delay || 0);
    // }

    get portalAvailable() {
        for (const [key, value] of Object.entries(this.purchases)) {
            if (value.web) {
                return true;
            }
        }
        return false;
    }
}

Vue.component('billing-page', BillingPage);

</script>

