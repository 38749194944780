<style lang="scss" scoped>
    @use '~ihunter-pathfinder/styles/legacy/common.scss' as Legacy;

    .header-tab {
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        flex-shrink: 0;

        height: 100%;
        align-items: center;

        user-select: none;
        pointer-events: none;

        .tab-item {

            pointer-events: all;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;

            margin-left: auto;

            min-width: Legacy.$half;
            height: 100%;

            position: relative;
        }


        &:hover {
            // background-color: Legacy.$brand-i-h-black-dark;

            &.active {
                .close:before {
                    color: Legacy.$mono-glow;
                }
            }

            .tab-item {
                background-color: Legacy.$brand-i-h-black-dark;
                // background: Legacy.$mono-black;
            }

            .underline {
                background-color: Legacy.$mono-white;
            }
        }

        &.active {
           // @include below(large)  {
                .tab-item {
                    background: Legacy.$mono-black;
                }
           // }

            .label {
                @include Legacy.Label-active;
            }

            .close:before {
                content: 'x';
                color: Legacy.$mono-dark;

                &:hover {
                    color: Legacy.$mono-glow;
                }


            }

            .underline {
                background-color: Legacy.$general-orange;
            }
        }
        
        .label {
            text-transform: uppercase;
            @include Legacy.Label-inactive;
        }

        .icon {
            margin: auto;

        }

        .underline {
            width: Legacy.$icon-size;
            height: Legacy.$notch;
            margin: Legacy.$notch auto 0 auto;
            border-radius: Legacy.$notch Legacy.$notch 0 0;
        }

        .close {
            position: absolute;
            top: 0;
            right: Legacy.$space;
        }
    }


</style>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import AppState from './AppState.vue';

    @Component
    export default class HeaderTab extends Vue.extend({
        props: {
            label: {
                type: String
            },
            active: {
                type: Boolean,
                default: false
            },
            count: {
                type: Number
            }
        }
    }) {
        click(event) {
            this.$emit('click', event);
        }

        get showBadge() {
            return this.count && this.count != 0;
        }

        get id() {
            return this.count;
        }

        get state() {
            return AppState;
        }

        navigate(route, args) {
            this.$parent.navigate(route, args);
        }

        get route() {
            return this.$parent.route;
        }

        get args() {
            return this.$parent.args;
        }
    }


</script>

