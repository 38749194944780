<template>
    <div v-if="renewal">
        <div v-if="future">
            Change date: <b>{{ toDate(renewal) }}</b>
        </div>
        <div v-else-if="cancelled">
            Expiry date: <b>{{ toDate(renewal) }}</b>
        </div>
        <div v-else>
            Renewal date: <b>{{ toDate(renewal) }}</b>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    @Component
    export default class BillingSubscriptionDate extends Vue.extend({
        props: {
            info: {
                type: Object,
                required: false
            }
        }
    }) {

        toDate(timestamp) {
            const options = {
                // weekday: 'long',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            };

            return new Date(timestamp * 1000).toLocaleDateString("en-US", options);
        }
        get renewal() {
            return this.info?.renewal;
        }

        get future() {
            return this.info?.status === 'future';
        }

        get cancelled() {
            return this.info?.cancelled;
        }

    }

    Vue.component('billing-subscription-date', BillingSubscriptionDate);

</script>

