<style lang="scss" scoped>
    @use '~ihunter-pathfinder/styles/legacy/common.scss' as Legacy;

    .list-item {
        position: relative;
        display: flex;
        flex-direction: row;

        color: Legacy.$mono-black;

        overflow: hidden; // Round outside corners; no scrollbars

        .icon-section {
            display: flex;
            align-items: center;
            margin: 0 Legacy.$gap;
        }

        .item-content {
            display: flex;
            flex-direction: row;
            width: 100%;
            padding: Legacy.$space 0;
        }

        .info-section {
            flex-grow: 1;

            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-rows: auto 1fr auto;

            grid-template-areas:
            'secondary alternate'
            'title     price'
            'details   recurring';
            
            .title {
                grid-area: title;
                a{
                    font-size: 16px;
                    font-weight: normal;
                    color: Legacy.$mono-black;
                }
            }

            .secondary {
                grid-area: secondary;

                padding: Legacy.$notch 0; 
                font-size: 11px;
                text-align: left;
                color: Legacy.$general-orange-dark;
            }

            .price {
                grid-area: price;
                padding: 0 Legacy.$space;
                text-align: left;
                @include Legacy.Price-18;
            }

            .alternate{
                grid-area: alternate;
                padding: Legacy.$notch Legacy.$space; 
                text-align: right;
                @include Legacy.Text-11;
                text-decoration: line-through;
            }

            .recurring {
                grid-area: recurring;
                font-size: 11px;
                text-align: right;
                color: Legacy.$mono-black;
            }

            .details {
                grid-area: details;
                font-size: 12px;
                color: Legacy.$mono-dark;
                //padding: 0 $space;
            }
        }


        .action-section {
            grid-area: action;
            flex-grow: 0;

            display: flex;
            flex-direction: row;

            padding: 0 Legacy.$space;
        }

        &:hover {
            .title {
                a:visited, a:active, a:focus, a:link { 
                    text-decoration: underline; 
                    color: Legacy.$general-blue; 
                }

                a:hover {
                    color: Legacy.$general-blue-dark; 
                }
            }

            .details {
                color: Legacy.$mono-black;
            }
        }

        &.cart {
            .action-section {
                background-color: transparent;
            }
        }
    }

</style>

<template>

    <div class="list-item" :class="{cart: incart}" @click="click">
        <div class="icon-section">
            <layer-icon :type="type" fill="#D92121"></layer-icon>
        </div>
        <div class="item-content">
            <div class="info-section">
                <div class="secondary" v-if="subscription">Subscription</div>
                <div class="secondary" v-else-if="map && outdated">Update {{productVersion(outdated)}} ▸ {{version}}</div>
                <div class="secondary" v-else-if="map">{{version}}</div>

                <div class="title"><a :href="`#/${type}/${sku}`" @click.stop>{{name}}</a></div>
                <div class="details" v-if="map"><b>{{region}}</b></div>
                <div class="alternate" v-if="alternate">${{alternate}}</div>

                <div class="price">
                    <div v-if="!owned">
                        ${{price}}
                        <div class="recurring" v-if="subscription">/year</div>
                    </div>
                    <div v-else>
        
                        <platform-label :purchase="purchase"></platform-label>
                    </div>
                </div>

                
            </div>
            
            <div class="action-section" v-if="!owned">
                <product-cart-button :sku="sku"></product-cart-button>
            </div>
        </div>

        <sash v-if="alternate" :small="true"></sash>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import ProductItem from './ProductItem.vue';

    @Component
    export default class ProductListItem extends ProductItem {

        click() {
            if(this.owned){
                return;
            }

            if(this.cart.contains(this.sku)){
                this.cart.remove(this.sku);
            }else {
                this.cart.buy(this.sku);
            }
        }
    }

    Vue.component('product-list-item', ProductListItem);

</script>

