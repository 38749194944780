<style lang="scss">
    @use '~ihunter-pathfinder/styles/legacy/common.scss' as Legacy;

    .product-list {
        @include Legacy.item-list-separator;
        //display: block;
        //display: inline-block;
        .card-grid {
            width: 100%;
        }
    }
    
</style>

<template>

    <div class="product-list">

        <div v-if="showCards" class="card-grid">
            <product-card v-for="(product, sku) in products" :key="sku" :sku="sku" :product="product"></product-card>
        </div>

        <ul v-else class="list-grid">
            <li v-for="(product, sku) in sortedProducts" :key="sku">
                <product-list-item :sku="product?.sku" :product="product"></product-list-item>
            </li>
        </ul>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    @Component
    export default class ProductList extends Vue.extend({
        props: {
            products: {
                type: Object,
                required: true
            }
        }
    }) {

        get total() {
            return Object.keys(this.products).length;
        }

        get showCards() {
            return this.total <= 6;
        }

        get sortedProducts() {
            const IGNORE_PREFIX = [
                "MUNICIPALITY OF ",
                "RM OF "
            ];

            return Object.values(this.products).sort((a, b) => {
                let trimPrefix = (str, prefix) => {
                    str = str?.toUpperCase();
                    for (let i = 0; i < prefix.length; i++) {
                        if (str?.startsWith(prefix[i])) {
                            return str.slice(prefix[i].length);
                        }
                    }
                    return str;
                }

                let cleanA = trimPrefix(a.name || a.map || a.sku, IGNORE_PREFIX);
                let cleanB = trimPrefix(b.name || b.map || b.sku, IGNORE_PREFIX);

                return cleanA.localeCompare(cleanB);
            });
        }
    }

    Vue.component('product-list', ProductList);

</script>

