<style lang="scss" scoped>
    @use '~ihunter-pathfinder/styles/legacy/common.scss' as Legacy;

    .content {
        margin: Legacy.$icon-size;

        button {
            // height: 44px;
            width: 100%;
        }

        .item {
            justify-content: space-between;
            display: flex;
            flex-direction: row;

            :last-child {
                text-align: right;
                font-weight: bold;
            }
        }
    }

</style>

<template>
    <div class="content">
        <h2>{{user?.displayName}}</h2>
        <div><span class="orange">{{user?.email}}</span></div>
        <div><span class="red" v-if="!isProduction">{{projectId}}</span></div>
        <div class="item"><span>Account created:</span><span v-text="creationTime"></span></div>
        <div class="item">Last login: <span v-text="lastSignInTime"></span></div>
        <p class="secondary-title">My Content</p>
        <div class="item">Waypoints: <span v-text="this.summary?.waypoints"></span></div>
        <div class="item">Tracks<span v-text="this.summary?.tracks"></span></div>
        <div class="item">Drawings: <span v-text="this.summary?.drawings"></span></div>
        <div class="item">Contacts: <span v-text="this.summary?.contacts"></span></div>
        <div class="item">Map Layers: <span v-text="this.summary?.layers"></span></div>
        <button v-on:click="logout()" class="secondary">Sign out</button>
        <button v-on:click="confirm()">Confirm</button>
    </div>
</template>

<script>
    import { Component, Vue } from 'vue-property-decorator';
    import Environment from 'common/Environment';

    import AppState from './AppState.vue';

    @Component
    export default class AccountConfirmation extends Vue {

        summary = null;

        mounted() {
            this.loadSummary();
        }

        async loadSummary() {
            try {
                if(this.user) {
                    // User authorized; we have a token
                    if(!this.summary) {
                        this.summary = await this.dal.fetchUserSummary();
                    }

                } else {
                    // User is signed out
                }

            }catch(error) {
                this.state.error.notify(new Error(`Unable to load account summary`, error));
            }
        }

        confirm() {
            this.$emit('confirm');
        }

        logout() {
            this.$emit('logout');
        }

        get user() {
            return this.state.user;
        }

        get state() {
            return AppState;
        }

        get creationTime() {
            let time = this.user?.metadata?.creationTime;
            if(time) {
                let date = new Date(time);
                return date.toLocaleDateString();
            }
            return null;
        }

        get lastSignInTime() {
            let time = this.user?.metadata?.lastSignInTime;
            if(time) {
                let date = new Date(time);
                return date.toLocaleDateString();
            }
            return null;
        }


        get photo() {
            return this.user?.photoURL;
        }
        
        get dal() {
            return this.state.dal;
        }

        get isProduction() {
            return Environment.isProduction();
        }

        get projectId() {
            return Environment.projectId;
        }
    }


    Vue.component('account-confirmation', AccountConfirmation);
    
</script>
