<script>
    import Component from 'vue-class-component';
    import Page from './Page.vue';

    import JsonViewer from 'vue-json-viewer';

    @Component({
        components: {
            JsonViewer
        }
    })
    export default class ProductPage extends Page {

        mounted() {
            if(this.validSku) {
                setTimeout(() => {
                    this.analytics.reportItemView(this.sku, this.name, this.price, this.alternate);
                }, 2000); // Give time for catalog to fully load and ensure the page is actually viewed
            }
        }

        get validSku() {
            if(!this.catalogLoaded || !this.sku) {
                return false;
            }
            
            return this.products[this.sku];
        }

        get sku() {
            if(!this.args || typeof this.args !== 'string') {
                return null;
            }

            let parts = this.args.split('/');
            return parts[0];
        }

        get short() {
            return this.state?.catalog[this.info?.region]?.short;
        }

        get info() {
            if (!this.validSku) {
                return null;
            }
            return this.state.productInfo(this.sku);
        }

        get region() {
            return this.info?.region;
        }

        get price() {
            return this.info?.price;
        }

        get alternate() {
            return this.info?.alternate;
        }

        get name() {
            return this.info?.name;
        }
    }

</script>