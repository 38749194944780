<style lang="scss" scoped>
    @use '~ihunter-pathfinder/styles/legacy/common.scss' as Legacy;

    .updates {
        padding-top: Legacy.$xsmall-size;

        @include Legacy.icon-section;
    }

</style>

<template>

    <div class="updates">
        <profile-icon class="profile icon" :icon="photo"></profile-icon>
        
        <div class="content">
            <div class="secondary-title">My account</div>
            <div class="title">Map updates</div>
            <chip/>
            <purchase-update-section></purchase-update-section>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import Page from './Page.vue';

    @Component
    export default class UpdatesPage extends Page {

        mounted() {
            if(!this.authenticated) {
                this.navigate();
            }
        }
    }

    Vue.component('updates-page', UpdatesPage);

</script>

