// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/platform-icon-apple.svg";
import ___CSS_LOADER_URL_IMPORT_1___ from "../assets/platform-icon-android.svg";
import ___CSS_LOADER_URL_IMPORT_2___ from "../assets/platform-icon-store.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*[data-v-21432d9e],*[data-v-21432d9e]::before,*[data-v-21432d9e]::after{box-sizing:border-box}*[data-v-21432d9e]:focus{outline:none}html[data-v-21432d9e]{position:relative;font-family:\"Lato\",\"Trebuchet MS\",sans-serif;font-weight:normal;font-style:normal;font-stretch:normal;font-size:14px;line-height:1.5;-webkit-text-size-adjust:100%;min-height:100%}span[data-v-21432d9e]{font-weight:normal}[tabindex=\"-1\"][data-v-21432d9e]:focus{outline:0 !important}.platform-icon[data-v-21432d9e]{display:inline-flex;flex-direction:row;align-self:center;height:24px}.platform-icon svg[data-v-21432d9e]{fill:#777}.platform-icon img[data-v-21432d9e]{padding:3px;height:24px}.platform-icon .apple[data-v-21432d9e]{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");margin-right:2px}.platform-icon .android[data-v-21432d9e]{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");margin-right:2px}.platform-icon .web[data-v-21432d9e]{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");margin-right:-2px}", "",{"version":3,"sources":["webpack://./../../ihunter_pathfinder/styles/legacy/base.scss","webpack://./src/PlatformIcon.vue","webpack://./../../ihunter_pathfinder/styles/legacy/layout.scss","webpack://./../../ihunter_pathfinder/styles/legacy/colors.scss"],"names":[],"mappings":"AAGA,wEAGI,qBAAA,CAGJ,yBACI,YAAA,CAGJ,sBACI,iBAAA,CACA,4CAAA,CACA,kBAAA,CACA,iBAAA,CACA,mBAAA,CACA,cAAA,CACA,eAAA,CACA,6BAAA,CACA,eAAA,CAGJ,sBACI,kBAAA,CAkBJ,uCACI,oBAAA,CC1CJ,gCACI,mBAAA,CACA,kBAAA,CACA,iBAAA,CACA,WCmBQ,CDjBR,oCACI,SEDI,CFKR,oCACI,WAAA,CACA,WCUI,CDNR,uCACI,+CAAA,CACA,gBAAA,CAGJ,yCACI,+CAAA,CACA,gBAAA,CAGJ,qCACI,+CAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
