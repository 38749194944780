<style lang="scss" scoped>
    @use '~ihunter-pathfinder/styles/legacy/common.scss' as Legacy;

    $card-hover-colour: Legacy.$mono-glow;// #faf8f0; // This colour is unnamed in Zeplin

    $card-height: 145px;
    $action-button-height: 48px;

    .item {
        position: relative;
        display: flex;
        flex-direction: column;

        width: 100%;
        height: $card-height;
        
        background-color: Legacy.$mono-white;
        color: Legacy.$mono-black;

        border-radius: 8px;
        border: solid 1px Legacy.$mono-misc;

        overflow: hidden; // Round outside corners; no scrollbars

        .info-section {
            grid-area: info;
            padding: Legacy.$space;
            flex-grow: 1;

            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-rows: auto 1fr;

            grid-template-areas:
            'secondary alternate'
            'title price';
            
            .title {
                grid-area: title;
                padding: 0 Legacy.$space;
                a{
                    font-size: 16px;
                    font-weight: normal;
                    color: Legacy.$mono-black;
                }
            }

            .secondary {
                grid-area: secondary;

                padding: Legacy.$notch Legacy.$space; 
                font-size: 11px;
                text-align: left;
                color: Legacy.$general-orange-dark;
            }

            .price {
                grid-area: price;
                padding: 0 Legacy.$space;
                text-align: left;
                @include Legacy.Price-18;
            }

            .alternate{
                grid-area: alternate;
                padding: Legacy.$notch Legacy.$space; 
                text-align: left;
                @include Legacy.Text-11;
                text-decoration: line-through;
            }

            .recurring {
                grid-area: recurring;
                font-size: 11px;
                text-align: right;
                color: Legacy.$mono-black;
            }
        }


        .action-section {
            grid-area: action;
            flex-grow: 0;

            display: flex;
            flex-direction: row;
            min-height: $action-button-height;
            width: 100%;
            background-color: Legacy.$general-blue;

            padding: 0 Legacy.$space;

            .action-icon {
                display: flex;
                align-items: center;
                text-align: center;
                margin: 0 Legacy.$space;

                svg {
                    fill: Legacy.$mono-white;
                }
            }

            .action-text {
                display: flex;
                margin: 0 Legacy.$notch;

                align-items: center;
                text-align: center;
                

                span {
                    font-size: 12px;
                    color: Legacy.$mono-white;
                    text-transform: uppercase;
                }
            }
        }

        &:hover {
            background-color: $card-hover-colour;

            .action-section {
                background-color: Legacy.$general-blue-dark;
            }
        }

        &.cart {
            .action-section {
                background-color: transparent;

                .action-icon {                   
                    svg {
                        fill: Legacy.$mono-light;
                    }
                }

                .action-text {                   

                    span {
                        color: Legacy.$mono-light;
                    }
                }
            }
        }
    }

</style>

<template>
    <div class="item" :class="{cart: incart}" @click="buy">
        <div class="info-section">
            <div class="secondary" v-if="subscription">Subscription</div>
            <div class="secondary" v-else-if="map && outdated">Update {{productVersion(outdated)}} ▸ {{version}}</div>
            <div class="secondary" v-else-if="map">{{version}}</div>

            <div class="title"><a :href="`#/${type}/${sku}`" @click.stop>{{name}}</a></div>
            <div class="alternate" v-if="alternate">${{alternate}}</div>
            <div class="price">
                ${{price}}
                <div class="recurring" v-if="subscription">/year</div>
            </div>
        </div>
        
        <div class="action-section" v-if="!owned">
            <div class="action-icon">
                <cart-icon></cart-icon>
            </div>
            <div class="action-text">
                <span v-if="!incart">Add to cart</span>
                <span v-else >In cart</span>
            </div>
        </div>

        <sash v-if="alternate"></sash>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import ProductItem from './ProductItem.vue';

    @Component
    export default class ProductCard extends ProductItem {

    }

    Vue.component('product-card', ProductCard);

</script>

