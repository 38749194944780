import api from "!../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../node_modules/vue-style-loader/index.js!../../node_modules/css-loader/dist/cjs.js!../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-5.use[3]!../../node_modules/vue-loader/lib/index.js??vue-loader-options!./PlatformIcon.vue?vue&type=style&index=0&id=21432d9e&prod&lang=scss&scoped=true&";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};