<style lang="scss" scoped>
@use '~ihunter-pathfinder/styles/legacy/common.scss' as Legacy;

.billing-item {
    display: flex;
    flex-direction: column;

    .icon {
        border-radius: Legacy.$space;

        margin: Legacy.$icon-size 0;

        width: Legacy.$xsmall-size;
        height: Legacy.$xsmall-size;
        min-width: Legacy.$xsmall-size;
    }

    .content {
        .title {
            @include Legacy.Title-18;
            font-weight: bold;
            
            .generic {
                color: Legacy.$generic-yellow;
            }
        }

        .action-button-row {
            display: flex;
            flex-direction: row;
            padding-top: Legacy.$space;

            div {
                margin-right: Legacy.$gap + Legacy.$gap;
            }
        }

        .extra {
            display: none;
        }

        .details {
            .more {
                font-size: 16px;
            }
        }
    }

    @include Legacy.above(medium) {
        flex-direction: row;
        margin: 0;

        .icon {
            margin: Legacy.$space;
        }

        .content {
            margin: Legacy.$gap Legacy.$gap 0 Legacy.$gap;
            width: 100%;

            .extra {
                display: inline;
                font-weight: bold;
            }
        }
    }
}
</style>

<template>
    <div class="billing-item">
        <div class="top">
            <app-icon class="icon" :region="productInfo?.region"></app-icon>
        </div>

        <div class="content">
            <div class="title">{{ name }} <span class="generic">{{productInfo?.generic}}</span></div>
            <div v-if="cancelled" class="details">
                <p class="more">This subscription has been set to expire. All features are still active until the expiry date.</p>
            </div>
            <div v-else-if="phasing" class="details">
                <p class="more">Your current <b>{{ upgradeInfo?.name }}</b> subscription is scheduled to downgrade. All features are still active until the change date.</p>
                <p>New subscription price at renewal: <b>${{ productInfo?.price }} / year.</b></p>
            </div>

            <billing-subscription-date :info="info"></billing-subscription-date>
            
            <!-- 
            <div v-if="future">
                <p>Change date: <b>{{ toDate(item.renewal) }}</b></p>
            </div>
            <div v-else-if="cancelled">
                <p>Expiry date: <b>{{ toDate(item.renewal) }}</b></p>
            </div>
            <div v-else>
                <p>Renewal date: <b>{{ toDate(item.renewal) }}</b></p>
            </div> -->

            <div class="action-button-row">
                <div v-if="action?.downgrade">
                    <a href="javascript:void(0)" @click="downgradeSubscription" class="arrow">Downgrade to Core ▸</a>
                </div>
                <div v-if="action?.upgrade">
                    <a v-if="phasing" href="javascript:void(0)" @click="upgradeSubscription" class="arrow">Undo change ▸</a>

                    <a v-else href="javascript:void(0)" @click="confirmUpgradeSubscription" class="arrow">Upgrade to Pro ▸</a>
                </div>
                <div v-if="action?.renew">
                    <a href="javascript:void(0)" @click="renewSubscription" class="arrow">Set to renew ▸</a>
                </div>
                <div v-else>
                    <a href="javascript:void(0)" @click="cancelSubscription" class="arrow secondary">Cancel<span class="extra"> subscription</span> ▸</a>
                </div>
            </div>

            <dialog-box v-if="processing === 'upgrade'" :title="title" action="Upgrade now" @action="upgradeSubscription" @cancel="processing=null">
                <p>Selecting <i>“Upgrade now” </i> will immediately change your current subscription to
                    <b>{{ upgradeInfo?.name }}</b> and charge you the difference in price based on the time left until your
                    renewal date.</p>
                <p class="details">At the next renewal you will charged the regular {{ upgradeInfo?.name }} price: <b>${{ upgradeInfo?.price }} /
                    year.</b></p>
            </dialog-box>

            <dialog-box v-else-if="processing" :title="title" :cancel="null" :action="null">
                <p>Your subscription details will refresh in just a moment...</p>
            </dialog-box>


            <!-- <code>{{ item }}</code> -->
            <!-- <code>{{  state?.products[item.sku] }}</code> -->
            <!-- <code>{{ productInfo }}</code> -->
            <slot></slot>
        </div>

    </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import AppState from './AppState.vue';

@Component
export default class BillingSubscriptionItem extends Vue.extend({
    props: {
        info: {
            type: Object,
            required: true
        }
    }
}) {

    processing = null;

    get title() {
        return this.processing.charAt(0).toUpperCase() + this.processing.slice(1) + ' subscription';
    }    

    get phasing() {
        return this.info?.phasing;
    }

    get cancelled() {
        return this.info?.cancelled || this.info.status === 'phase-out';
    }

    get name() {
        if (!this.productInfo?.generic) {
            return this.info?.name;
        }
        return this.info?.name.replace(this.productInfo?.generic, '');
    }

    get action() {
        let action = {
            cancel: true
        };

        if (this.cancelled) {
            action.renew = true;
            action.cancel = false;

        } else if (this.info?.group && this.info?.group?.length > 1) {
            action.downgrade = true;
            action.upgrade = true;

            if (this.info.group.indexOf(this.sku) === 0) { // Ranked first
                action.downgrade = false;

            } else if (this.info.group.indexOf(this.sku) === this.info.group.length - 1) { // Ranked last
                action.upgrade = false;
            }
        }

        return action;
    }

    get sku() {
        return this.info?.sku;
    }

    get upgradeSku() {
        return this.info?.group[1];
    }

    get downgradeSku() {
        return this.info?.group[0];
    }

    get state() {
        return AppState;
    }

    get upgradeInfo() {
        return this.upgradeSku ? this.state.productInfo(this.upgradeSku) : null;
    }

    get downgradeInfo() {
        return this.upgradeSku ? this.state.productInfo(this.downgradeSku) : null;
    }

    get productInfo() {
        return this.state.productInfo(this.sku);
    }

    async confirmUpgradeSubscription() {
        this.processing = 'upgrade';
    }

    async upgradeSubscription() {

        try {
            this.processing = 'upgrading';
            await this.state.dal.updateSubscription(this.sku, this.upgradeSku);

        } catch (error) {
            this.state.error.notify(new Error(`Subscription upgrade error (${error.message})`, error));

        } finally {
            this.processing = null;
            await this.state.refreshSubscriptions();
        }
    }

    async downgradeSubscription() {
        try {
            this.processing = 'downgrading';
            await this.state.dal.updateSubscription(this.sku, this.downgradeSku);

        } catch (error) {
            this.state.error.notify(new Error(`Subscription downgrade error (${error.message})`, error));

        } finally {
            this.processing = null;
            await this.state.refreshSubscriptions();
        }
    }

    async renewSubscription() {
        try {
            this.processing = 'renewing';
            await this.state.dal.renewSubscription(this.sku);

        } catch (error) {
            this.state.error.notify(new Error(`Subscription renewal error (${error.message})`, error));

        } finally {
            this.processing = null;
            await this.state.refreshSubscriptions();
        }
    }

    async cancelSubscription() {
        try {
            this.processing = 'cancelling';
            await this.state.dal.cancelSubscription(this.sku, this.downgradeSku);
            
        } catch (error) {
            this.state.error.notify(new Error(`Subscription cancel error (${error.message})`, error));

        } finally {
            this.processing = null;
            await this.state.refreshSubscriptions();
        }
    }
}

Vue.component('billing-subscription-item', BillingSubscriptionItem);

</script>

