<style lang="scss" scoped>
    @use '~ihunter-pathfinder/styles/legacy/common.scss' as Legacy;

    .region-card {
        display: flex;
        flex-direction: row;
        overflow: hidden;

        //min-width: Legacy.$unit;
        height: Legacy.$large-size;

        // @include above(large)  {
        //     height: Legacy.$large-size;
        // }

        //pointer-events: none;
        user-select: none;
        cursor: pointer;

        border-radius: Legacy.$space;
        background-color: Legacy.$brand-i-h-black;

        color: Legacy.$mono-misc;

       // border: solid 2px transparent;

        &:hover {

            background-color: Legacy.$mono-black;

            color: Legacy.$general-blue-light;

            .arrow {
                display: initial;
            }
        }

        &:focus, &:active {
            background-color: Legacy.$mono-black;
            border: solid 2px Legacy.$general-orange;
        }

        .arrow {
            display: none;
        }

        .region-labels {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 Legacy.$gap;
            
            span {
                font-size: 24px;
                //font-weight: bold;
            }
        }
    }

</style>

<template>
    <div class="region-card" :class="{active}" role="listitem" @click="click">
        <app-icon :region="id"></app-icon>
        <div class="region-labels">
            <div><span>Shop</span><span class="arrow">&nbsp;▸</span></div>
            <word-logo>{{short}}</word-logo>
        </div>
        <!-- <slot class="slot"></slot> -->
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import AppIcon from 'components/AppIcon.vue';


    @Component({
        components: {
            AppIcon
        }
    })
    export default class RegionCard extends Vue.extend({
        props: {
            active: {
                type: Boolean
            },
            region: {
                type: Object,
               // required: true
            }
        }
    }) {

        click(event) {
            this.$emit('click');
        }

        get id() {
            return this.region?.id;
        }
        get name() {
            return this.region?.name;
        }
        get short() {
            return this.id?.toUpperCase();
        }
    }

    Vue.component('region-card', RegionCard);

</script>

