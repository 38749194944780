<style lang="scss" scoped>
    @use '~ihunter-pathfinder/styles/legacy/common.scss' as Legacy;

    .purchase-summary {
        // margin: 0 30px;
        background-color: Legacy.$mono-white;

        p {
            color: Legacy.$mono-light;
        }

        .header {
            margin: 0 Legacy.$icon-size;
        }

        .region-area {
            @include Legacy.item-list;

            .item-row {
                
                padding: Legacy.$notch Legacy.$icon-size;
                .name {
                    @include Legacy.Title-16;
                }
            }
        }

        .warning-content {
            display: flex;
            flex-direction: row;

            svg {
                min-width: Legacy.$gap;
                min-height: Legacy.$gap;
                margin: Legacy.$gap Legacy.$gap 0 0;
            }
        }
    }

</style>

<template>
    <div class="purchase-summary">
        <div class="header" v-if="emptyPurchases" v-show="showHeader">
            <h2>No purchases</h2>
            <chip/>
            <div class="warning-content">
                <svg xmlns="http://www.w3.org/2000/svg" data-name="Icon 12 • Alert" width="16" height="16" viewBox="0 0 16 16">
                    <path d="M167.281 550.333h-14.572a.7.7 0 0 1-.608-1.053l7.293-11.941a.717.717 0 0 1 1.219 0l7.282 11.926a.7.7 0 0 1-.614 1.068zm-6.929-1.411a1.253 1.253 0 0 0 .425-.915 1 1 0 0 0-.3-.736 1.018 1.018 0 0 0-.741-.3 1.425 1.425 0 0 0-1.4 1.364.938.938 0 0 0 .3.716 1.053 1.053 0 0 0 .749.279 1.353 1.353 0 0 0 .967-.407zm-1.051-6.384c-.089.748-.183 1.411-.276 1.989s-.141.895-.141.948a.573.573 0 0 0 .275.509 1.283 1.283 0 0 0 .713.18 2.251 2.251 0 0 0 1-.252 2.025 2.025 0 0 1-.029-.411 12.107 12.107 0 0 1 .411-2.752 14.8 14.8 0 0 0 .409-1.968q0-.784-1.131-.783a5.052 5.052 0 0 0-1.129.145 3.856 3.856 0 0 1 .028.571 15.96 15.96 0 0 1-.131 1.825z" transform="translate(-152 -535.667)" style="fill:#777"/>
                </svg>
                <p class="details">Not what you were expecting? If you have made purchases from your mobile device it’s possible they aren’t currently associated with an iHunter Account. Please visit the <a href="/#/support">Help and support page</a> for more information.</p>
            </div>
        </div>
        <div class="header" v-else v-show="showHeader">
            <h2>My purchases</h2>
            <chip/>
            <!-- <p class="details">This is a short list to help with confirmation. You can find a more detailed version on the My Purchases page after signing in.</p> -->
        </div>
        <div class="region-area" v-for="(products, region) in regionalPurchases" :key="region">
            <div class="section-row"><span class="section-title">iHUNTER {{region.toUpperCase()}} <b>{{products.length}}</b></span><span class="item-count"></span></div>
            
            <div class="item-row" v-for="product in products" :key="product?.sku">
                <div>
                    <span class="name" v-if="product?.name">{{product?.name}}</span>
                    <span class="details" v-if="product?.version">{{product?.version}}</span>
                </div>
                <platform-label v-if="product" :purchase="product.purchase"></platform-label>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import AppState from './AppState.vue';

    @Component
    export default class PurchaseSummary extends Vue.extend({
        props: {
            showHeader: {
                type: Boolean,
                default: false
            }
        }
    }) {

        error = null;
        //purchases = {};

        created() {
            //this.loadUserPurchases();
        }

        mounted() {
            // this.$forceUpdate();
        }

        get emptyPurchases() {
            return !this.purchases || Object.keys(this.purchases).length === 0;
        }

        get purchases() {
            return AppState.purchases || {};
        }

        get catalog() {
            return AppState.catalog || {};
        }

        get products() {
            return AppState.products || {};
        }

        get user() {
            return AppState.user;
        }

        get state() {
            return AppState;
        }



        get regionalPurchases() {
            return this.state.regionalPurchases;
        }
        // map(region, resource, layer, map) {
        //     const product = this.products[sku];
        //     if(!product) {
        //         return {};
        //     }

        //     let region = product?.region;
        //     let resource = product?.resource;
        //     let layer = product?.layer;
        //     let map = product?.map;
        //     return this.catalog[region].resources[resource].layers[layer].maps[map];
        // }

        productInfo(sku) {
            return this.state.productInfo(sku) || {};
        }

        region(id) {
            return this.state.catalog[id];
        }

        // async loadUserPurchases() {

        //     this.purchases = {};
            
        //     try {
        //         this.purchases = await this.dal.fetchUserPurchases();

        //         return;
                
        //         if(!this.purchases) {
        //             return;
        //         }
                

        //         // Inject product metadata for all purchases VM
        //         for(let province in this.purchases) {
        //             // Copy required provincial metadata to the purchases VM
        //             this.purchases[province].name ??= await this.products.getProvinceName(province);

        //             // Check for public lands subscription (aka. is subscribed)
        //             if(this.purchases[province].subscription) {
        //                 this.purchases[province].subscription.name ??= await this.products.getSubscriptionName(province);
        //             }

        //             // Check for county purchases
        //             if(this.purchases[province].county) {
        //                 for(let sku in this.purchases[province].county) {
        //                     this.purchases[province].county[sku].name ??= sku.replace(/_/g, ' ');//await this.products.getCountyName(province, sku);
        //                 }
        //             }
        //         }

        //     }catch(error) {
        //         console.error(error);
        //         this.error = error;

        //     }finally {
        //         this.$forceUpdate(); // Flush VM updates to the UI
        //     }
        // }

        get isFirstTimeLogin() {
            return this.creationTime === this.lastSignInTime;
        }

        get creationTime() {
            let time = this.user?.metadata?.creationTime;
            if(time) {
                let date = new Date(time);
                return date.toLocaleDateString();
            }
            return null;
        }

        get lastSignInTime() {
            let time = this.user?.metadata?.lastSignInTime;
            if(time) {
                let date = new Date(time);
                return date.toLocaleDateString();
            }
            return null;
        }

        get catalog() {
            return window.app.vm.catalog;
        }

        getPlatforms(obj) {
            return Object.keys(obj).join(', ');
        }
    }


    Vue.component('purchase-summary', PurchaseSummary);
    
</script>
