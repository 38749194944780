<style lang="scss" scoped>
    @use '~ihunter-pathfinder/styles/legacy/common.scss' as Legacy;

    .item {

        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: 100%;
        background-color: Legacy.$mono-grey;

        .info-section {
            margin: Legacy.$space Legacy.$space 0 0;
            flex-grow: 1;

            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-rows: auto 1fr;

            grid-template-areas:
            'title price'
            'secondary secondary';

            
            .title {
                grid-area: title;
                padding: 0 Legacy.$space;

                font-size: 13px;
                font-weight: normal;
                color: Legacy.$mono-black;
            }

            .secondary {
                grid-area: secondary;

                padding: Legacy.$notch Legacy.$space; 

                text-align: left;
                @include Legacy.Text-11;
            }

            .price {
                grid-area: price;
                padding: 0 Legacy.$space;
                text-align: left;
                @include Legacy.Price-13;
            }
        }

        .action-row {
            display: flex;

            .view {
                padding: 0 Legacy.$space;
                a {
                    @include Legacy.URL-11;
                    color: Legacy.$general-blue-light;
                }
            }

            .remove {
                padding: 0 Legacy.$space;
                a {
                    @include Legacy.Nega-URL-11;
                }
            }
        }
    }
    
</style>

<template>

    <div class="item">

        <div class="info-section">

            <div class="title">{{name}}</div>
            <div class="secondary" v-if="subscription">Renews annually</div>
            <div class="secondary" v-else-if="map && outdated">Update {{productVersion(outdated)}} ▸ {{version}}</div>
            <div class="secondary" v-else-if="map">{{version}} landowner map</div>

            <!-- <div class="alternate" v-if="alternate">${{alternate}}</div> -->
            <div class="price">
                ${{price}}
            </div>
            <!-- <div class="recurring" v-if="subscription">/year</div> -->
        </div>

        <div class="action-row">
            <div class="view">
                <a :href="`#/${type}/${sku}`" @click.stop>View item</a>
            </div>
            <div class="remove">
                <a href="javascript:void(0)" @click.stop="remove">Remove</a>
            </div>
        </div>

        <sash v-if="alternate" small></sash>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import ProductItem from './ProductItem.vue';

    @Component
    export default class CartItem extends ProductItem {

        view() {
            //this.cart.remove(this.sku);
        }

        remove() {
            this.cart.remove(this.sku);
        }
    }

    Vue.component('cart-item', CartItem);

</script>

