<style lang="scss" scoped>
    @use '~ihunter-pathfinder/styles/legacy/common.scss' as Legacy;

    .app-notice {
        display: flex;
        flex-direction: column;
        user-select: none;

        .section {
            min-width: Legacy.$unit;
            max-width: Legacy.$unit-full;
            margin: Legacy.$space;

            img {
                margin-right: Legacy.$gap;
            }

            .main {
                font-size: 16px;
                color: Legacy.$mono-white;
                margin: Legacy.$gap 0;
            }

            .icon-buttons {

                margin: Legacy.$gap 0;
            }

            .secondary {
                @include  Legacy.Notification;
                margin: Legacy.$gap 0;
                p {
                    margin: Legacy.$notch 0;
                }
            }
        }

        .desktop {
            display: none;
        }

        .social {
            .secondary {
                color: Legacy.$mono-light;
            }
        }
        
        @include Legacy.above(small)  {

            flex-direction: row;
        }

        @include Legacy.above(medium)  {

            .desktop {
                display: initial;
            }
        }
    }

</style>

<template>
    <div class="app-notice">

        <div class="section desktop">
            <div class="main">
                Use iHunter on your desktop:
            </div>

            <div class="icon-buttons">
                <web-app-badge></web-app-badge>
                <!-- <a v-bind:href="web" target="blank">
                    <img src="../assets/ihunter-web-button.svg" alt='iHunter Web'>
                </a> -->

            </div>

            <div class="secondary">
                Put your purchases to use planning your next hunt. Log in with your iHunter account to use the web version for free in your desktop browser.
            </div>
        </div>

        <div class="section mobile">
            <div class="main">
                Mobile app available on your App Store:
            </div>

            <div class="icon-buttons">
                <!-- <a href="https://itunes.apple.com/us/app/ihunter/id570558600?ls=1" target="blank">
                    <img src="../assets/app-store-apple.svg" alt='Download on the App Store'>
                </a> -->
                <app-store-badge></app-store-badge>
                <play-store-badge></play-store-badge>
                <!-- <a href="https://play.google.com/store/apps/developer?id=Inside+Outside+Studios+Inc.&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="blank">
                    <img src="../assets/app-store-google.svg" alt='Get it on Google Play'>
                </a> -->
            </div>

            <div class="secondary">
            </div>
        </div>

        <div class="section social">
            <div class="main">
            </div>

            <div class="icon-buttons">
                <a href="https://www.instagram.com/ihunterapp/" target="blank"><img src="../assets/instagram.svg"></a>
                <a href="https://www.facebook.com/ihunterapp" target="blank"><img src="../assets/facebook.svg"></a>
                <a href="https://www.youtube.com/channel/UCV0PyPKUCq_RjaPH6kS1SRA" target="blank"><img src="../assets/youtube.svg"></a>
            </div>

            <div class="secondary">
                <p>Visit <a href="https://www.ihunterapp.com">ihunterapp.com</a> for more product details.</p>
                <p>Made by <a href="https://www.insideoutsidestudios.com">Inside Outside Studios Inc.</a></p>
                <p>Designed and developed in Canada. 🍁</p>
            </div>
        </div>

        <slot></slot>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    @Component
    export default class AppNotice extends Vue {


    }

    Vue.component('app-notice', AppNotice);

</script>

